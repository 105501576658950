<div class="root">
  <div class="header">
    <div class="title-wrapper">
      <app-header-template
        translationKey="Project.ProjectUsers.Title"
        [showBackButton]="false"
      >
        @if (isAddProjectMembersFlagDisabled) {
          <div class="add-user-button">
            <button
              class="tertiary-btn"
              aria-label="Add members to a project"
              (click)="openDialog()"
              data-cy="open-dialog-btn"
            >
              {{ "UserList.AddNewMembers" | translate }}
            </button>
          </div>
        }
      </app-header-template>
    </div>
    <app-function-bar [searchFilterControl]="searchFilterControl">
      <div class="search-bar-end-items" others>
        <div class="select-container">
          <label for="alphabet-order-select">Alphabetical</label>
          <app-select-menu
            id="alphabet-order-select"
            [options]="alphabeticalOptions"
            [selectedOption]="selectedAlphabetOption()"
            (optionSelected)="alphabetFilterSelect($event)"
          ></app-select-menu>
        </div>
        <app-list-grid-toggle
          [selectedView]="projectUsersView"
          (viewChanged)="projectUsersView = $event"
        ></app-list-grid-toggle>
      </div>
    </app-function-bar>
  </div>
  @if (projectUsersData$ | async; as projectUsersData) {
    <div class="project-user-list">
      <app-content-wrapper
        [isLoading]="projectUsersData.isLoading"
        [isError]="projectUsersData.hasError"
        [emptySectionScope]="EmptySectionScope.PAGE"
      >
        <app-users-list
          [users]="projectUsersData.projectUsers"
          [grid]="projectUsersView === 'grid'"
          (removeUserFromProject)="removeUserFromProject(projectId, $event)"
        ></app-users-list>
      </app-content-wrapper>
    </div>
    <ng-template #addUserDialogContent>
      <app-add-user-dialog
        *ngIf="tenantUsers$ | async as users"
        #userDialogRef
        [tenantUserList]="users"
        [usersAlreadyAdded]="existingProjectUserList"
      ></app-add-user-dialog>
    </ng-template>
  }
</div>

import { Injectable } from '@angular/core';
import { catchError, map, throwError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectsByTenant } from '../../../../shared/stores/projects/models/project';
import { TenantsByUser } from '../../../../shared/stores/projects/models/tenants';
import { environment } from '../../../../../environments/environment';
import {
  BlueprintDeployment,
  BlueprintDeploymentResponse,
} from '../../../../shared/stores/config/models/blueprintDeployments';
import { FeatureConfig } from '../../../../../environments/featureConfig';
import { FeatureService } from '../../config/feature.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  isBluePrintServiceReady: boolean;
  constructor(
    private http: HttpClient,
    private featureService: FeatureService,
  ) {
    this.isBluePrintServiceReady = this.featureService.isFeatureEnabled(
      FeatureConfig.bluePrintServiceReady,
    );
  }

  getTenantsFromJWT(): Observable<TenantsByUser> {
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      'tenants/from_jwt_token';
    return this.http.get(configUrl) as Observable<TenantsByUser>;
  }

  getProjects(tenant_id: string) {
    // For PG Tenant only the projects from the user should be shown
    const allProjects = `tenants/${tenant_id}/projects`;
    const playGroundProjects = 'projects/my_projects';
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      (tenant_id === 'T010' ? playGroundProjects : allProjects);
    return this.http.get(configUrl) as Observable<ProjectsByTenant>;
  }

  getProjectBlueprintDeployments(
    projectId: string,
  ): Observable<BlueprintDeployment[]> {
    if (!this.isBluePrintServiceReady) {
      return throwError(
        () =>
          new Error(
            'Backend not ready error - feature will be implemented soon',
          ),
      );
    }

    // dummy file: '/assets/dummyData/dummyConfigFile.json',
    return this.http
      .get<BlueprintDeploymentResponse>(
        `${environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL}projects/${projectId}/blueprintdeployments?stage=${environment.AWS_STAGE}`,
      )
      .pipe(
        map((response) => response.BlueprintDeployments),
        catchError((err) => {
          console.error(err);
          return of([] as BlueprintDeployment[]);
        }),
      );
  }
}

import { Contact } from '../../../../features/home/components/add-project-dialog/models/contact';

export interface BlueprintDeploymentResponse {
  BlueprintDeployments: BlueprintDeployment[];
}

export interface BlueprintDeployment {
  pkey: string;
  skey: string;
  ProjectId: string;
  TenantId: string;
  AccountId?: string;
  AccountName: string;
  Description: string;
  Stage: Stage;
  BlueprintProductId: string;
  BlueprintUpdateEnabled: string;
  BlueprintProduct: BlueprintProduct;
}

export interface BlueprintProduct {
  Blueprints: Blueprint[];
  Requester: Contact;
  CreatedAt: string;
  UpdatedAt: string;
  Status: BlueprintStatus;
}

export interface Blueprint {
  BlueprintId: string;
  BlueprintName: BlueprintName;
  BlueprintVersion: string;
  AccountId?: string;
  environment_variables?: {};
}

export enum BlueprintStatus {
  CREATE_INITIATED = 'CREATE_INITIATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
}

export enum BlueprintName {
  // HDK = 'HDK',
  // VHPC = 'VHPC',
  // Observability = 'Observability',
  HDK = 'hdk-workbench',
  VHPC = 'vhpc-workbench',
  Observability = 'obs-app',
}

export enum Stage {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'Prod',
}
